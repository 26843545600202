import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Form, Input, Label, Modal, ModalBody, Popover, PopoverBody, Row, Table } from 'reactstrap';
import { Btn, H4, H5, H6 } from '../../../AbstractElements';
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import { useComment } from '../../../_helper/Comments/CommentProvider';
import { useProject } from '../../../_helper/Projects/ProjectProvider';
import { useReport } from '../../../_helper/Reports/ReportProvider';
import Chat from '../../Chat';
import Loader from "../Loader";
import { FiCopy } from "react-icons/fi";
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import Masonry from 'react-masonry-css'
import './style.scss'
import useIsMobile from '../../../Hooks/useIsMobile';

const CommonModal = ({ data, isOpen, toggler, size, bodyClass }) => {
  const isMobile = useIsMobile();
  const { theme, isDark, authToken } = useAuth();
  const { t } = useTranslation();
  const { isFetchingChecklist, isFetchingComments, comments, getComments, checklist, setChecklist, getChecklist, isTaskUpdate, setIsTaskUpdate, updateTaskDetails, getReports, getMyReports } = useReport();
  const { projectAssetsList, getAllProjectAssets } = useProject();
  const { isCommentSubmitted, setIsCommentSubmitted } = useComment()
  const [popoverIndex, setPopoverIndex] = useState(null);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [openItems, setOpenItems] = useState({});
  let totalPercentage = (checklist.reduce((acc, current) => acc + current.percent, 0) / (checklist.length * 100)) * 100;
  totalPercentage = isNaN(totalPercentage) ? 0 : totalPercentage;

  const breakpointColumnsObj = {
    default: 1,
    1199: 1,
    700: 1,
    500: 1,
  }

  const handleCopyClick = () => {
    const textToCopy = data?._id; // Change this to the text you want to copy
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        toast.success('Asset-ID copied successfully...', { autoClose: 1000 });
      })
      .catch((err) => {
        toast.success('Failed to copy Asset-ID...', { autoClose: 1000 });
        console.error('Error copying text: ', err);
      });
  };

  const toggleChecklist = (itemId) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [itemId]: !prevOpenItems[itemId]
    }));
  };

  const [combinedArray, setCombinedArray] = useState(null);
  useEffect(() => {
    if (projectAssetsList.length !== 0 && data?.typeId) {
      const projectAssetFormList = projectAssetsList?.filter((i) => i._id === data.typeId);
      if (projectAssetFormList.length !== 0) {
        const assetFormList = projectAssetFormList[0];
        const customizeEntries = assetFormList?.customizeEntries || [];
        const groupedArray = (assetFormList?.properties || []).map((properties) => {
          const options = { option: customizeEntries.filter(customizeEntry => properties.customizeType === customizeEntry._id) };
          return { ...properties, options };
        });
        const combinedArray = combineArrays(groupedArray, data?.properties || []);
        // Ensure combinedArray is assigned to the outer scope variable
        setCombinedArray(combinedArray);
      }
    }
  }, [projectAssetsList, data?.typeId, data?.properties]);

  function combineArrays(array1, array2) {
    const map = array1.reduce((acc, obj) => {
      acc[obj._id] = obj;
      return acc;
    }, {});

    const combinedArray = array2.map(obj => {
      const matchedObject = map[obj.propertyTypeEntryId];
      if (matchedObject) {
        return { ...matchedObject, ...obj };
      } else {
        return obj;
      }
    });

    return combinedArray;
  }

  //refresh comment component if comment success
  if (isCommentSubmitted) {
    setTimeout(() => {
      setIsCommentSubmitted(false)
      getComments(authToken, data?._id);
    }, 100)
  }

  if (isTaskUpdate) {
    setIsTaskUpdate(false)
    getChecklist(authToken, data?._id);
    setTimeout(() => {
      getReports(authToken);
      getMyReports(authToken);
    }, 1000)
  }

  useEffect(() => {
    const fetchData = () => {
      if (isOpen) {
        getChecklist(authToken, data?._id);
        getComments(authToken, data?._id);
        getAllProjectAssets(data?.typeId);
      }
    };
    fetchData();
  }, [isOpen, data?.typeId]);

  const handleMouseEnter = (index) => {
    setPopoverIndex(index);
  };

  const handleMouseLeave = () => {
    setPopoverIndex(null);
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '(...)';
    } else {
      return text;
    }
  }

  //   const handleCheckboxChange = async (index, item, checklist_item) => {

  //     const updatedChecklist = item.checkList.map(checklistItem => {
  //         if (checklist_item._id === checklistItem._id) {
  //             return {
  //                 ...checklistItem,
  //                 checked: !checklistItem.checked,
  //             };
  //         }
  //         return checklistItem;
  //     });

  //     // Calculate total percentage
  //     let totalPercentage = 0;
  //     const checked = updatedChecklist.filter((i)=>i.checked).map((item)=>{return(item)})
  //     totalPercentage = ((checked.length / (updatedChecklist.length * 100)) * 100) * 100;

  //     const updateTaskData = {
  //         data: {
  //             ...item,
  //             checkList: updatedChecklist,
  //             percent: totalPercentage.toFixed(2)
  //         }
  //     }

  //     console.log('updateTaskData', totalPercentage);
  //     updateTaskDetails(updateTaskData)
  // };

  // const handleCheckboxChange = async (index, item, checklist_item) => {
  //   // Optimistically update the UI
  //   const updatedChecklist = item.checkList.map(checklistItem => {
  //     if (checklist_item._id === checklistItem._id) {
  //       return {
  //         ...checklistItem,
  //         checked: !checklistItem.checked,
  //       };
  //     }
  //     return checklistItem;
  //   });

  //   // Calculate total percentage
  //   const checkedItems = updatedChecklist.filter(i => i.checked);
  //   const totalPercentage = ((checkedItems.length / updatedChecklist.length) * 100).toFixed(2);

  //   const updatedItem = {
  //     ...item,
  //     checkList: updatedChecklist,
  //     percent: totalPercentage,
  //   };

  //   // Optimistically update the state
  //   setChecklist(items.map((itm, idx) => (idx === index ? updatedItem : itm)));

  //   // Prepare data for API call
  //   const updateTaskData = {
  //     data: updatedItem,
  //   };

  //   // Perform the API call
  //   try {
  //     await updateTaskDetails(updateTaskData);
  //   } catch (error) {
  //     console.error('Error updating item:', error);

  //     // Revert the change if the API call fails
  //     const revertedChecklist = item.checkList.map(checklistItem => {
  //       if (checklist_item._id === checklistItem._id) {
  //         return {
  //           ...checklistItem,
  //           checked: !checklistItem.checked,
  //         };
  //       }
  //       return checklistItem;
  //     });

  //     const revertedItem = {
  //       ...item,
  //       checkList: revertedChecklist,
  //       percent: ((item.checkList.filter(i => i.checked).length / item.checkList.length) * 100).toFixed(2),
  //     };

  //     setChecklist(items.map((itm, idx) => (idx === index ? revertedItem : itm)));
  //   }
  // };

  const handleCheckboxChange = async (index, item, checklist_item) => {
    // Optimistically update the UI
    const updatedChecklist = item.checkList.map(checklistItem => {
      if (checklist_item._id === checklistItem._id) {
        return {
          ...checklistItem,
          checked: !checklistItem.checked,
        };
      }
      return checklistItem;
    });

    // Calculate total percentage
    const checkedItems = updatedChecklist.filter(i => i.checked);
    const totalPercentage = ((checkedItems.length / updatedChecklist.length) * 100).toFixed(2);

    const updatedItem = {
      ...item,
      checkList: updatedChecklist,
      percent: totalPercentage,
    };

    // Optimistically update the state
    setChecklist(checklist.map((itm, idx) => (idx === index ? updatedItem : itm)));

    // Prepare data for API call
    const updateTaskData = {
      data: updatedItem,
    };

    // Perform the API call
    try {
      await updateTaskDetails(updateTaskData);
    } catch (error) {
      console.error('Error updating item:', error);

      // Revert the change if the API call fails
      const revertedChecklist = item.checkList.map(checklistItem => {
        if (checklist_item._id === checklistItem._id) {
          return {
            ...checklistItem,
            checked: !checklistItem.checked,
          };
        }
        return checklistItem;
      });

      const revertedItem = {
        ...item,
        checkList: revertedChecklist,
        percent: ((item.checkList.filter(i => i.checked).length / item.checkList.length) * 100).toFixed(2),
      };

      setChecklist(checklist.map((itm, idx) => (idx === index ? revertedItem : itm)));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggler}
      size={size}
      centered
    >
      <ToastContainer autoClose={1000} />
      <Loader />
      <div style={{ padding: 20 , display: 'flex', justifyContent: 'space-between', alignItems: 'start' }} className='pb-0'>
      <div style={{ marginRight: isMobile?80:20}}>
        <H6>{data?.assetTypeName} -
            <span style={{ paddingLeft: 5 }}>{
              combinedArray?.[0]?.mode === 1 ? combinedArray?.[0]?.valueBool
                : combinedArray?.[0]?.mode === 2 ? combinedArray?.[0]?.valueInt
                  : combinedArray?.[0]?.mode === 3 ? combinedArray?.[0]?.valueDateTime?.slice(0, 10)
                    : combinedArray?.[0]?.mode === 4 && combinedArray?.[0]?.customizeType === "TEXT" ? combinedArray?.[0]?.valueString
                      : combinedArray?.[0]?.mode === 4 && combinedArray?.[0]?.customizeType === "TEXT2" ? combinedArray?.[0]?.valueString
                        : combinedArray?.[0]?.mode === 5 ? combinedArray?.[0]?.valueDecimal
                          : combinedArray?.[0]?.valueString
            }</span>
          </H6>
        </div>
        
      {/* Close Button */}
        {
          isMobile ? (
            <div 
              style={{ 
                display: 'flex', 
                flexDirection: 'column', // Stack the elements vertically
                justifyContent: 'flex-start', // Align to the top
                alignItems: 'flex-end', // Align to the right
                gap: '5px', // Space between elements for responsiveness
                position: 'absolute', // Ensure absolute positioning
                top: '0', // Align to the top of the screen/container
                right: '0', // Float the entire container to the right
                padding: 16
              }}
            >
              <Btn 
                attrBtn={{ color: 'primary', onClick: toggler }}
                style={{
                  zIndex: 2, // Ensure the button is above the span
                }}
              >
                {'X'}
              </Btn>
              <span
                style={{
                  color: '#fff',
                  //backgroundColor: totalPercentage === 0 ? '#7468fc' : totalPercentage >= 1 && totalPercentage <= 99 ? '#ee2266' : '#60bb4f',
                  backgroundColor: totalPercentage === 0 ? '#bebfbf'
                  : totalPercentage >= 1 && totalPercentage <= 98
                  ? '#f07b05'
                  : '#77b800',
                  padding: '7px 10px',
                  borderRadius: '4px',
                  whiteSpace: 'nowrap',
                  zIndex: 1, // Ensure it's below the button
                }}
              >
                {totalPercentage.toFixed(0)} %
              </span>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <span 
                style={{ 
                  color: '#fff', 
                  backgroundColor: totalPercentage === 0 ? '#bebfbf'
                  : totalPercentage >= 1 && totalPercentage <= 98
                  ? '#f07b05'
                  : '#77b800',
                  padding: '7px 20px', 
                  borderRadius: '4px', 
                  marginRight: '8px',
                  whiteSpace: 'nowrap', 
                }}
              >
                {totalPercentage.toFixed(0)} %
              </span>
              <Btn 
                attrBtn={{ color: 'primary', onClick: toggler }}
              >
                {'X'}
              </Btn>
            </div>
          )
        }

      </div>
      <div className="flex items-center">
        <div style={{ display: 'flex', paddingLeft: 20 }} className="pb-3">
          ID: {data?._id} <span style={{ paddingLeft: 10 }} onClick={handleCopyClick}><FiCopy size={15} /></span>
        </div>
        {/* {copySuccess && <p className="ml-2 text-sm text-green-500">{copySuccess}</p>} */}
        
        {/* {
          isMobile &&
          <span
          style={{
            color: '#fff',
            backgroundColor: totalPercentage === 0 ? '#7468fc' : totalPercentage >= 1 && totalPercentage <= 99 ? '#ee2266' : '#60bb4f',
            padding: '7px 20px',
            borderRadius: '4px',
            whiteSpace: 'nowrap',
            zIndex: 1, // Ensure it's below the button
            marginLeft: 16
          }}
        >
          {totalPercentage.toFixed(0)} %
        </span>
        } */}
        
      </div>

      <ModalBody className={bodyClass}>
        {/* <H5>{t('Form')}</H5> */}
        <Form className="needs-validation mb-5" noValidate="">
          <Row>
            {/* {combinedArray?.map((form,index)=>(
                form.mode &&
                <Col sm="6" key={index}>
                  <div className="form-floating mb-3">
                    {form.mode === 2 ? <input className="form-control" id='validationCustom01' type='number' value={form.mode === 2 && form.valueInt} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                      : form.mode === 3 ? <input className="form-control" id='validationCustom01' type='date' value={form.mode === 3 && form.valueDateTime ? form.valueDateTime.slice(0, 10) : ''} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                      : form.mode === 4 && form.customizeType === "TEXT" ? <input className="form-control" id='validationCustom01' type='text' value={form.mode === 4 && form.valueString} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                      : form.mode === 4 && form.customizeType === "TEXT2" ? <textarea className="form-control" style={{ height: "auto", minHeight: 80, maxHeight: "300px" }} value={form.mode === 4 && form.valueString} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })}
                      ref={(textarea) => {
                        if (textarea) {
                          textarea.style.height = "auto";
                          textarea.style.height = (textarea.scrollHeight) + "px";
                        }
                      }}></textarea> 
                      : form.mode === 7 ? 
                      <select
                        className="form-select form-control"
                        id={`Name${index}`}
                        name={`Name${index}`}
                        {...register(`Name${index}`, { required: form.mandatory })}
                        value={form.mode === 7 && form.valueString}
                      >
                        {form.options.option[0].predefiniedProperties.map((property, i) => (
                          <option key={i} value={property.value}>
                            {property.value}
                          </option>
                        ))}
                      </select>
                      : <input className="form-control" id='validationCustom01' type='text' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                    }
                    <label htmlFor="floatingInput22">{form.mandatory === true&&<span style={{color:'#F07B05'}}>*</span>}{form.name}</label>
                    <span className='d-block' style={{color:'#F07B05'}}>{errors[`Name${index}`] && t('This field is required')}</span>
                  </div>
                </Col>
              ))
            } */}

            <Col>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid masonry-with-dec my-gallery gallery-with-description row grid"
                columnClassName="col-xl-12 col-sm-12 col-md-12 grid-item"
              >
                {
                  combinedArray?.map((form, index) => (
                    // <div className="form-floating mb-3 position-relative">
                    //   {form.mode === 2 ? <input className="form-control" id='validationCustom01' type='number' value={form.mode === 2 && form.valueInt} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} />
                    //     : form.mode === 3 ? <input className="form-control" id='validationCustom01' type='date' value={form.mode === 3 && form.valueDateTime ? form.valueDateTime.slice(0, 10) : ''} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} />
                    //       : form.mode === 4 && form.customizeType === "TEXT" ? <input className="form-control" id='validationCustom01' type='text' value={form.mode === 4 && form.valueString} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} />
                    //         : form.mode === 4 && form.customizeType === "TEXT2" ?
                    //           <textarea className="form-control" style={{ height: "auto", minHeight: 80, maxHeight: "300px" }} value={form.mode === 4 && form.valueString} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })}
                    //             onInput={(e) => {
                    //               e.target.style.height = "auto";
                    //               e.target.style.height = (e.target.scrollHeight) + "px";
                    //             }}></textarea>
                    //           // <textarea className="form-control" style={{ height: "auto", minHeight: 80, maxHeight: "300px" }} value={form.mode === 4 && form.valueString} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })}
                    //           // ref={(textarea) => {
                    //           //   if (textarea) {
                    //           //     textarea.style.height = "auto";
                    //           //     textarea.style.height = (textarea.scrollHeight) + "px";
                    //           //   }
                    //           // }}></textarea> 
                    //           : form.mode === 7 ?
                    //             <select
                    //               className="form-select form-control"
                    //               id={`Name${index}`}
                    //               name={`Name${index}`}
                    //               {...register(`Name${index}`, { required: form.mandatory })}
                    //               value={form.mode === 7 && form.valueString}
                    //             >
                    //               {form.options.option[0].predefiniedProperties.map((property, i) => (
                    //                 <option key={i} value={property.value}>
                    //                   {property.value}
                    //                 </option>
                    //               ))}
                    //             </select>
                    //             //: <input className="form-control" id='validationCustom01' type='text' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                    //             :
                    //             <div className="radio-container">
                    //               <label className="custom-radio-label w-100" htmlFor={`Name${index}`}>
                    //                 {form.mandatory === true && <span style={{ color: '#F07B05' }}>*</span>}
                    //                 {form.name}
                    //               </label>

                    //               <div className="radio-options" style={{ display: 'flex', alignItems: 'center' }}>
                    //                   <div className="radio-option" style={{ display: 'flex', alignItems: 'center' }}>
                    //                     <input
                    //                       className="custom-radio"
                    //                       id={`no-${index}`}
                    //                       type="radio"
                    //                       name={`Name${index}`}
                    //                       value="false"
                    //                       checked={form.valueBool === false}
                    //                       {...register(`Name${index}`, { required: true })}
                    //                     />
                    //                     <label className="custom-radio-label" htmlFor={`no-${index}`} style={{ marginLeft: '8px' }}>
                    //                     {t('No')}
                    //                     </label>
                    //                   </div>

                    //                   <div className="radio-option" style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                    //                     <input
                    //                       className="custom-radio"
                    //                       id={`yes-${index}`}
                    //                       type="radio"
                    //                       name={`Name${index}`}
                    //                       value="true"
                    //                       checked={form.valueBool === true}
                    //                       {...register(`Name${index}`, { required: true })}
                    //                     />
                    //                     <label className="custom-radio-label" htmlFor={`yes-${index}`} style={{ marginLeft: '8px' }}>
                    //                     {t('Yes')}
                    //                     </label>
                    //                   </div>
                    //                 </div>
                    //             </div>

                    //     // <div class="checkbox-container">
                    //     //   <input className="custom-checkbox" id='validationCustom01' type='checkbox' name={`Name${index}`} checked={form.valueBool} {...register(`Name${index}`, { required: form.mandatory })} /> 
                    //     //   <label className="custom-check-label w-100" htmlFor={`Name${index}`}>{form.mandatory === true&&<span style={{color:'#F07B05'}}>*</span>}{form.name}</label>
                    //     // </div>
                    //   }
                    //   {/* <label htmlFor="floatingInput22">{form.mandatory === true&&<span style={{color:'#F07B05'}}>*</span>}{form.name}</label> */}
                    //   {
                    //     form.mode !== 1 &&
                    //     <label htmlFor="floatingInput22" className="w-100">{form.mandatory === true && <span style={{ color: '#F07B05' }}>*</span>}{form.name}</label>
                    //   }
                    //   <span className='d-block' style={{ color: '#F07B05' }}>{errors[`Name${index}`] && t('This field is required')}</span>
                    // </div>

                    <div className="form-group mb-3" style={{ position: "relative" }}>
                      {/* Input Field Based on Form Mode */}
                      {form.mode === 2 ? (
                        <input
                          className="form-control"
                          id={`validationCustom01-${index}`}
                          type="number"
                          value={form.mode === 2 && form.valueInt}
                          name={`Name${index}`}
                          {...register(`Name${index}`, { required: form.mandatory })}
                          style={{
                            minHeight: "40px",
                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                            transition: "padding-top 0.2s ease-in-out",
                          }}
                        />
                      ) : form.mode === 3 ? (
                        <input
                          className="form-control"
                          id={`validationCustom01-${index}`}
                          type="date"
                          value={form.mode === 3 && form.valueDateTime ? form.valueDateTime.slice(0, 10) : ""}
                          name={`Name${index}`}
                          {...register(`Name${index}`, { required: form.mandatory })}
                          style={{
                            minHeight: "40px",
                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                            transition: "padding-top 0.2s ease-in-out",
                            background: theme === 'dark-only'?'#1d1e26':'',
                            border: theme === 'dark-only'?'none':'',
                            color: theme === 'dark-only'?'#fff':''
                          }}
                        />
                      ) : form.mode === 4 && form.customizeType === "TEXT" ? (
                        <input
                          className="form-control"
                          id={`validationCustom01-${index}`}
                          type="text"
                          value={form.mode === 4 && form.valueString}
                          name={`Name${index}`}
                          {...register(`Name${index}`, { required: form.mandatory })}
                          style={{
                            minHeight: "40px",
                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                            transition: "padding-top 0.2s ease-in-out",
                            background: theme === 'dark-only'?'#1d1e26':'',
                            border: theme === 'dark-only'?'none':'',
                            color: theme === 'dark-only'?'#fff':''
                          }}
                        />
                      ) : form.mode === 4 && form.customizeType === "TEXT2" ? (
                        // <textarea
                        //   className="form-control textarea-custom-scrollbar"
                        //   id={`validationCustom01-${index}`}
                        //   value={form.mode === 4 && form.valueString}
                          // style={{
                          //   height: "auto",
                          //   minHeight: "80px",
                          //   maxHeight: 300,
                          //   paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                          //   transition: "padding-top 0.2s ease-in-out",
                          //   background: theme === 'dark-only'?'#1d1e26':'',
                          //   border: theme === 'dark-only'?'none':'',
                          //   color: theme === 'dark-only'?'#fff':''
                          // }}
                        //   name={`Name${index}`}
                        //   {...register(`Name${index}`, { required: form.mandatory })}
                        //   onInput={(e) => {
                        //     e.target.style.height = "auto";
                        //     e.target.style.height = `${e.target.scrollHeight}px`;
                        //   }}
                        // ></textarea>
                        <textarea 
                          className="form-control textarea-custom-scrollbar"   style={{
                            height: "auto",
                            minHeight: "80px",
                            //maxHeight: 300,
                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                            transition: "padding-top 0.2s ease-in-out",
                            background: theme === 'dark-only'?'#1d1e26':'',
                            border: theme === 'dark-only'?'none':'',
                            color: theme === 'dark-only'?'#fff':'',
                          }} value={form.mode === 4 && form.valueString} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })}
                          ref={(textarea) => {
                            if (textarea) {
                              textarea.style.height = "auto";
                              textarea.style.height = (textarea.scrollHeight) + "px";
                            }
                          }}></textarea> 

                      ) : form.mode === 5 ? (
                        <input
                          className="form-control"
                          id={`validationCustom01-${index}`}
                          type="number"
                          value={form.mode === 5 && form.valueDecimal}
                          name={`Name${index}`}
                          {...register(`Name${index}`, { required: form.mandatory })}
                          style={{
                            minHeight: "40px",
                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                            transition: "padding-top 0.2s ease-in-out",
                            background: theme === 'dark-only'?'#1d1e26':'',
                            border: theme === 'dark-only'?'none':'',
                            color: theme === 'dark-only'?'#fff':''
                          }}
                        />
                      ) : form.mode === 7 ? (
                        <select
                          className="form-select form-control"
                          id={`validationCustom01-${index}`}
                          name={`Name${index}`}
                          {...register(`Name${index}`, { required: form.mandatory })}
                          value={form.mode === 7 && form.valueString}
                          style={{
                            minHeight: "40px",
                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                            transition: "padding-top 0.2s ease-in-out",
                          }}
                        >
                          <option value=""></option>
                          {form.options.option[0].predefiniedProperties.map((property, i) => (
                            <option key={i} value={property.value}>
                              {property.value}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <div className="radio-container">
                          <label className="custom-radio-label w-100" htmlFor={`Name${index}`}>
                            {form.mandatory && <span style={{ color: "#F07B05" }}>*</span>}
                            {form.name}
                          </label>

                          <div className="radio-options" style={{ display: "flex", alignItems: "center" }}>
                            <div className="radio-option" style={{ display: "flex", alignItems: "center" }}>
                              <input
                                className="custom-radio"
                                id={`no-${index}`}
                                type="radio"
                                name={`Name${index}`}
                                value="false"
                                checked={form.valueBool === false}
                                {...register(`Name${index}`, { required: true })}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor={`no-${index}`}
                                style={{ marginLeft: "8px" }}
                              >
                                {t("No")}
                              </label>
                            </div>

                            <div
                              className="radio-option"
                              style={{ display: "flex", alignItems: "center", marginRight: "15px" }}
                            >
                              <input
                                className="custom-radio"
                                id={`yes-${index}`}
                                type="radio"
                                name={`Name${index}`}
                                value="true"
                                checked={form.valueBool === true}
                                {...register(`Name${index}`, { required: true })}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor={`yes-${index}`}
                                style={{ marginLeft: "8px" }}
                              >
                                {t("Yes")}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Label (Relative to Input) */}
                      {form.mode !== 1 && (
                        <label
                          htmlFor={`validationCustom01-${index}`}
                          className="form-label"
                          style={{
                            position: "absolute",
                            top: "8px",
                            left: "12px",
                            fontSize: "12px",
                            maxWidth: "calc(100% - 24px)",
                            wordWrap: "break-word",
                            transition: "top 0.2s ease-in-out",
                            color: theme === 'dark-only' ? "white" : "",
                          }}
                          ref={(el) => {
                            if (el) {
                              const labelHeight = el.offsetHeight;
                              el.parentElement.style.setProperty("--dynamic-label-height", `${labelHeight}px`);
                            }
                          }}
                        >
                          {form.mandatory && <span style={{ color: "#F07B05" }}>*</span>} {form.name}
                        </label>
                      )}

                      {/* Error Message */}
                      <span className="d-block" style={{ color: "#F07B05", marginTop: "5px" }}>
                        {errors[`Name${index}`] &&
                          (form.mode === 1
                            ? "Please choose one option and cannot submit before"
                            : t("This field is required"))}
                      </span>
                    </div>


                  ))
                }
              </Masonry>
            </Col>

            {/* <Col sm="12">
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  style={{ height: "auto", minHeight: 80, maxHeight: "300px" }}
                  value={data?.description}
                  ref={(textarea) => {
                    if (textarea) {
                      textarea.style.height = "auto";
                      textarea.style.height = (textarea.scrollHeight) + "px";
                    }
                  }}
                ></textarea>
                <label htmlFor="floatingInput22">{'Description'}</label>
              </div>
            </Col> */}
          </Row>
        </Form>

        <H5>Tasks</H5>
        <Table hover={false} className="mb-5">
          <tbody>
            {checklist.length === 0 ? <p className='text-center p-3'>{t('No Tasks')}</p> :
              (
                checklist.sort((a, b) => a.percent - b.percent).map((item, index) => (
                  <React.Fragment>
                    <tr onClick={() => toggleChecklist(item._id)}>
                      <td style={{ width: '20%', background: 'none', color: theme === 'dark-only' && '#ffffff99' }}>
                        <H6> {item.name} </H6>
                      </td>
                      <td className="text-center" style={{ width: '30%', background: 'none', color: theme === 'dark-only' && '#ffffff99' }} id={`Popover-${index}`} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} >
                        {item.description === '' ? '...' : truncateText(item.description, 40)}
                      </td>
                      {item.description !== '' && item.description.length >= 40 && (
                        <Popover placement="left" isOpen={popoverIndex === index} target={`Popover-${index}`} toggle={() => { }} >
                          <PopoverBody> {item.description} </PopoverBody>
                        </Popover>
                      )}

                      <td className="text-center"
                        style={{ width: '10%', color: '#fff', 
                          // background: item.percent === 0 ? '#7468fc' : item.percent >= 1 && item.percent <= 99 ? '#ee2266' : '#60bb4f', }} >
                          background: item.percent === 0 ? '#bebfbf'
                          : item.percent >= 1 && item.percent <= 98
                          ? '#f07b05'
                          : '#77b800' }} >
                        {item.percent} %
                      </td>
                      <td className="text-center" style={{ width: '10%', background: 'none', color: theme === 'dark-only' && '#ffffff99' }} >
                        <i className='fa fa-check-square fa-lg'></i> <span>{item.checkList.filter((i) => i.checked === true).map((item) => { return (item) }).length}/{item.checkList.length}</span>
                      </td>
                      <td className="text-center" style={{ width: '15%', background: 'none', color: theme === 'dark-only' && '#ffffff99' }} >
                        {item.responsibility}
                      </td>
                    </tr>

                    {openItems[item._id] && (
                      <td colSpan="5" style={{ padding: 0 }}>
                        <div className="checklist">
                          {item.checkList.map((checklist_item) => (
                            <div key={checklist_item._id} className="row py-1" style={{ background: theme === 'dark-only' && '#262932' }}>
                              <div className="col-md-2 p-2 rounded-start" style={{ background: theme === 'dark-only' ? '#343846' : '#f5f6fa' }}>
                                <label className="d-block" htmlFor={`chk-ani-${index}`}>
                                  <input
                                    className="checkbox_animated"
                                    id={`chk-ani-${index}`}
                                    type="checkbox"
                                    onChange={() => handleCheckboxChange(index, item, checklist_item)}
                                    checked={checklist_item.checked}
                                    disabled
                                  />
                                </label>
                              </div>
                              <div className="col-md-4 p-2" style={{ background: theme === 'dark-only' ? '#343846' : '#f5f6fa', color: theme === 'dark-only' && '#ffffff99' }}>
                                {checklist_item.checked ? <del>{checklist_item.text}</del> : checklist_item.text}
                              </div>
                              <div className="col-md-6 p-2 rounded-end" style={{ background: theme === 'dark-only' ? '#343846' : '#f5f6fa', color: theme === 'dark-only' && '#ffffff99' }}>
                                {checklist_item.description === '' ? '...' : checklist_item.description}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* {item.checkList.map((checklist_item) => (
                        <div className="row py-1" style={{background:theme === 'dark-only'&&'#262932'}}>
                          <div className="col-md-2 p-2 rounded-start" style={{ background: isDark?'#343846':'#f5f6fa' }} >
                            <Label className="d-block" for={`chk-ani-${index}`}>
                              <Input
                                className="checkbox_animated"
                                id={`chk-ani-${index}`}
                                type="checkbox"
                                onChange={() => handleCheckboxChange(index, item, checklist_item)}
                                checked={checklist_item.checked}
                                //disabled
                              />
                            </Label>
                          </div>
                          <div className="col-md-4 p-2" style={{ background: isDark?'#343846':'#f5f6fa', color:isDark&&'#ffffff99' }} >
                            {checklist_item.checked ? <del>{checklist_item.text}</del> : checklist_item.text}
                          </div>
                          <div className="col-md-6 p-2 rounded-end" style={{ background: isDark?'#343846':'#f5f6fa', color:isDark&&'#ffffff99' }} >
                            {checklist_item.description === '' ? '...' : checklist_item.description}
                          </div>
                        </div>
                      ))} */}

                      </td>
                    )}

                  </React.Fragment>
                ))
              )}
          </tbody>
        </Table>

        <H5>Comments</H5>
        <Chat data={data} />

      </ModalBody>
    </Modal>
  );
}

export default CommonModal;
