import React, { useState, useEffect } from 'react';
import useIsMobile from '../../Hooks/useIsMobile';
import { useTranslation } from 'react-i18next';

const CustomPagination = ({ 
  totalItems, 
  itemsPerPage, 
  onPageChange, 
  onItemsPerPageChange,
  searchText
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate the start and end indexes dynamically
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalItems);

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  //use for displaying the search result if user search on second page and so on...
  useEffect(() => {
    if(searchText !==''){
      setCurrentPage(1);
    }
  }, [searchText]);

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    onItemsPerPageChange(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when itemsPerPage changes
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
  //   for (let i = 1; i <= totalPages; i++) {
  //     pageNumbers.push(
  //       <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
  //         <button className="page-link" onClick={() => handlePageChange(i)}>
  //           {i}
  //         </button>
  //       </li>
  //     );
  //   }
  //   return pageNumbers;
  // };

  const renderPageNumbers = () => {
    const pageNumbers = [];
  
    const buttonStyle = isMobile
      ? { width: '30px', height: '30px', padding: '0', textAlign: 'center' }
      : {};
  
    if (isMobile) {
      // Mobile: Show only the current page and the last page
      pageNumbers.push(
        <li key={currentPage} className={`page-item ${currentPage === currentPage ? 'active' : ''}`}>
          <button
            className="page-link"
            style={buttonStyle}
            onClick={() => handlePageChange(currentPage)}
          >
            {currentPage}
          </button>
        </li>
      );
  
      if (currentPage < totalPages - 1) {
        pageNumbers.push(
          <li key="ellipsis" className="page-item">
            <span className="page-link" style={buttonStyle}>...</span>
          </li>
        );
      }
  
      if (currentPage !== totalPages) {
        pageNumbers.push(
          <li key={totalPages} className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
            <button
              className="page-link"
              style={buttonStyle}
              onClick={() => handlePageChange(totalPages)}
            >
              {totalPages}
            </button>
          </li>
        );
      }
    } else {
      // Non-Mobile: Show standard pagination logic
      const totalVisible = 3; // Number of page numbers to show at a time
  
      // Determine the start and end pages
      let startPage = Math.max(currentPage - 1, 1);
      let endPage = Math.min(startPage + (totalVisible - 1), totalPages);
  
      // Generate page numbers
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
            <button
              className="page-link"
              style={buttonStyle}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          </li>
        );
      }
  
      // Show ellipsis if there are more pages before the visible ones
      if (startPage > 1) {
        pageNumbers.unshift(
          <li key="ellipsis-start" className="page-item">
            <span className="page-link" style={buttonStyle}>...</span>
          </li>
        );
      }
  
      // Add the first page if it's not already included
      if (startPage > 1) {
        pageNumbers.unshift(
          <li key={1} className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
            <button
              className="page-link"
              style={buttonStyle}
              onClick={() => handlePageChange(1)}
            >
              1
            </button>
          </li>
        );
      }
  
      // Show ellipsis and the last page if there are more pages after the visible ones
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <li key="ellipsis-end" className="page-item">
            <span className="page-link" style={buttonStyle}>...</span>
          </li>
        );
      }
  
      if (endPage < totalPages) {
        pageNumbers.push(
          <li key={totalPages} className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
            <button
              className="page-link"
              style={buttonStyle}
              onClick={() => handlePageChange(totalPages)}
            >
              {totalPages}
            </button>
          </li>
        );
      }
    }
  
    return pageNumbers;
  };
  

  return (
    <>
      {isMobile ? (
        <div>
          <nav aria-label="Page navigation" className="mt-4">
            <ul className="pagination flex-column align-items-center">
            <p
              className="for-light text-center m-0 mb-3"
              style={{
                fontSize: '14px',
                flexGrow: 1,
                whiteSpace: 'normal', // Allow text to wrap
                wordWrap: 'break-word', // Break long words when necessary
                overflowWrap: 'break-word', // Ensure that long words wrap correctly
              }}
            >
              {t('Showing')} {startIndex} {t('to')} {endIndex} {t('of')} {totalItems} {t('entries')}
            </p>
            <p
              className="for-dark text-white text-center m-0 mb-3"
              style={{
                fontSize: '14px',
                flexGrow: 1,
                whiteSpace: 'normal', // Allow text to wrap
                wordWrap: 'break-word', // Break long words when necessary
                overflowWrap: 'break-word', // Ensure that long words wrap correctly
              }}
            >
              {t('Showing')} {startIndex} {t('to')} {endIndex} {t('of')} {totalItems} {t('entries')}
            </p>

              {/* <p className="for-dark text-white text-center m-0 mb-3" style={{ fontSize: '14px', flexGrow: 1, whiteSpace: 'nowrap' }}>
              {t('Showing')} {startIndex} {t('to')} {endIndex} {t('of')} {totalItems} {t('entries')}
              </p> */}
              <div className="d-flex justify-content-center flex-wrap align-items-center gap-2 mt-3">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                    className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                    style={{ width: '30px', height: '30px' }}
                  >
                    <i style={{ fontSize: 16 }} className="fa fa-backward"></i>
                  </button>
                </li>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                    className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={{ width: '30px', height: '30px' }}
                  >
                    <i style={{ fontSize: 16 }} className="fa fa-chevron-left"></i>
                  </button>
                </li>
                {renderPageNumbers()}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button
                    className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    style={{ width: '30px', height: '30px' }}
                  >
                    <i style={{ fontSize: 16 }} className="fa fa-chevron-right"></i>
                  </button>
                </li>
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button
                    className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
                    onClick={() => handlePageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    style={{ width: '30px', height: '30px' }}
                  >
                    <i style={{ fontSize: 16 }} className="fa fa-forward"></i>
                  </button>
                </li>
              </div>
            </ul>
          </nav>
        </div>
      ) : (
        <div>
          <nav aria-label="Page navigation" className="mt-4">
            <ul className="pagination justify-content-end align-items-center">
              <p className="for-light text-center mt-3 p-3">
              {t('Showing')} {startIndex} {t('to')} {endIndex} {t('of')} {totalItems} {t('entries')}
              </p>
              <p className="for-dark text-white text-center mt-3 p-3">
              {t('Showing')} {startIndex} {t('to')} {endIndex} {t('of')} {totalItems} {t('entries')}
              </p>
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button
                  className="page-link rounded-circle d-flex justify-content-center align-items-center m-1 custom-btn"
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                  style={{
                    width: '40px', // Set fixed width
                    height: '40px', // Set fixed height
                    borderRadius: '50%', // Ensure circular shape
                    fontSize: '20px', // Adjust font size for icon
                  }}
                >
                  <i className="fa fa-backward"></i>
                </button>
              </li>
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button
                  className="page-link rounded-circle d-flex justify-content-center align-items-center m-1 custom-btn"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{
                    width: '40px', // Set fixed width
                    height: '40px', // Set fixed height
                    borderRadius: '50%', // Ensure circular shape
                    fontSize: '20px', // Adjust font size for icon
                  }}
                >
                  <i className="fa fa-chevron-left"></i>
                </button>
              </li>

              {renderPageNumbers()}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button
                  className="page-link rounded-circle d-flex justify-content-center align-items-center m-1 custom-btn"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  style={{
                    width: '40px', // Fixed width for perfect circle
                    height: '40px', // Fixed height for perfect circle
                    borderRadius: '50%', // Ensure circular shape
                    fontSize: '20px', // Adjust icon size
                  }}
                >
                  <i className="fa fa-chevron-right"></i>
                </button>
              </li>
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button
                  className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                  style={{
                    width: '40px', // Fixed width for perfect circle
                    height: '40px', // Fixed height for perfect circle
                    borderRadius: '50%', // Ensure circular shape
                    fontSize: '20px', // Adjust icon size
                  }}
                >
                  <i className="fa fa-forward"></i>
                </button>
              </li>

            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default CustomPagination;










// import React, { useState } from 'react';
// import useIsMobile from '../../Hooks/useIsMobile';

// const CustomPagination = ({ 
//   totalItems, 
//   itemsPerPage, 
//   onPageChange, 
//   onItemsPerPageChange, 
  // startIndex, 
  // endIndex 
// }) => {
//   const isMobile = useIsMobile();
//   const [currentPage, setCurrentPage] = useState(1);
//   const totalPages = Math.ceil(totalItems / itemsPerPage);

//   const handleItemsPerPageChange = (e) => {
//     const newItemsPerPage = parseInt(e.target.value);
//     onItemsPerPageChange(newItemsPerPage);
//   };

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//     onPageChange(page);
//   };

//   const renderPageNumbers = () => {
//     const pageNumbers = [];
//     for (let i = 1; i <= totalPages; i++) {
//       pageNumbers.push(
//         <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
//           <button className="page-link" onClick={() => handlePageChange(i)}>
//             {i}
//           </button>
//         </li>
//       );
//     }
//     return pageNumbers;
//   };

//   return (
//     <>
//       {isMobile ? (
//         <div>
//           <nav aria-label="Page navigation" className="mt-4">
//             <ul className="pagination flex-column align-items-center">
//               {/* <li className="page-item mb-2">
//                 <select
//                   className="form-select form-select-sm"
//                   onChange={handleItemsPerPageChange}
//                   value={itemsPerPage}
//                   style={{ maxWidth: '150px' }}
//                 >
//                   <option value="10">10 per page</option>
//                   <option value="20">20 per page</option>
//                   <option value="50">50 per page</option>
//                   <option value="100">100 per page</option>
//                 </select>
//               </li> */}
//               <p className="for-light text-center m-0 mb-3" style={{ fontSize: '14px', flexGrow: 1, whiteSpace: 'nowrap' }}>
//                 Showing {startIndex} to {endIndex} of {totalItems} entries
//               </p>
//               <p className="for-dark text-white text-center m-0 mb-3" style={{ fontSize: '14px', flexGrow: 1, whiteSpace: 'nowrap' }}>
//                 Showing {startIndex} to {endIndex} of {totalItems} entries
//               </p>
//               <div className="d-flex justify-content-center flex-wrap align-items-center gap-2 mt-3">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button
//                     className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
//                     onClick={() => handlePageChange(1)}
//                     disabled={currentPage === 1}
//                     style={{ width: '40px', height: '40px' }}
//                   >
//                     <i style={{ fontSize: 16 }} className="fa fa-backward"></i>
//                   </button>
//                 </li>
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button
//                     className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
//                     onClick={() => handlePageChange(currentPage - 1)}
//                     disabled={currentPage === 1}
//                     style={{ width: '40px', height: '40px' }}
//                   >
//                     <i style={{ fontSize: 16 }} className="fa fa-chevron-left"></i>
//                   </button>
//                 </li>
//                 {renderPageNumbers()}
//                 <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                   <button
//                     className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
//                     onClick={() => handlePageChange(currentPage + 1)}
//                     disabled={currentPage === totalPages}
//                     style={{ width: '40px', height: '40px' }}
//                   >
//                     <i style={{ fontSize: 16 }} className="fa fa-chevron-right"></i>
//                   </button>
//                 </li>
//                 <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                   <button
//                     className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
//                     onClick={() => handlePageChange(totalPages)}
//                     disabled={currentPage === totalPages}
//                     style={{ width: '40px', height: '40px' }}
//                   >
//                     <i style={{ fontSize: 16 }} className="fa fa-forward"></i>
//                   </button>
//                 </li>
//               </div>
//             </ul>
//           </nav>
//         </div>
//       ) : (
//         <div>
//           <nav aria-label="Page navigation" className="mt-4">
//             <ul className="pagination justify-content-end align-items-center">
//               {/* <li className="page-item">
//                 <select
//                   className="form-select form-select-sm"
//                   onChange={handleItemsPerPageChange}
//                   value={itemsPerPage}
//                 >
//                   <option value="10">10 per page</option>
//                   <option value="20">20 per page</option>
//                   <option value="50">50 per page</option>
//                   <option value="100">100 per page</option>
//                 </select>
//               </li> */}
//               <p className="for-light text-center mt-3 p-3">
//                 Showing {startIndex} to {endIndex} of {totalItems} entries
//               </p>
//               <p className="for-dark text-white text-center mt-3 p-3">
//                 Showing {startIndex} to {endIndex} of {totalItems} entries
//               </p>
//               <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                 <button
//                   className="page-link rounded-circle d-flex justify-content-center align-items-center m-1 custom-btn"
//                   onClick={() => handlePageChange(1)}
//                   disabled={currentPage === 1}
//                 >
//                   <i style={{ fontSize: 20 }} className="fa fa-backward"></i>
//                 </button>
//               </li>
//               <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                 <button
//                   className="page-link rounded-circle d-flex justify-content-center align-items-center m-1 custom-btn"
//                   onClick={() => handlePageChange(currentPage - 1)}
//                   disabled={currentPage === 1}
//                 >
//                   <i style={{ fontSize: 20 }} className="fa fa-chevron-left"></i>
//                 </button>
//               </li>
//               {renderPageNumbers()}
//               <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                 <button
//                   className="page-link rounded-circle d-flex justify-content-center align-items-center m-1 custom-btn"
//                   onClick={() => handlePageChange(currentPage + 1)}
//                   disabled={currentPage === totalPages}
//                 >
//                   <i style={{ fontSize: 20 }} className="fa fa-chevron-right"></i>
//                 </button>
//               </li>
//               <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                 <button
//                   className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn"
//                   onClick={() => handlePageChange(totalPages)}
//                   disabled={currentPage === totalPages}
//                 >
//                   <i style={{ fontSize: 20 }} className="fa fa-forward"></i>
//                 </button>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       )}
//     </>
//   );
// };

// export default CustomPagination;
