import React, { Fragment, useState, useLayoutEffect, useContext } from 'react';
import { Col } from 'reactstrap';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../../AbstractElements';
import CustomizerContext from '../../../_helper/Customizer';
import NotificationSlider from './NotificationSlider';
import InformLogo from '../../../assets/images/logo/inpro-logo.png';
import { useAuth } from '../../../_helper/Auth/AuthProvider'

const Leftbar = () => {
  const { layoutURL, toggleSidebar } = useContext(CustomizerContext);
  const [sidebartoggle, setSidebartoggle] = useState(true);
  const width = useWindowSize();
  const { theme, isDark, setIsDark } = useAuth()

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
        if (window.innerWidth <= 1007) {
          toggleSidebar(true);
          document.querySelector('.page-wrapper').className = 'page-wrapper compact-wrapper';
        } else {
          toggleSidebar(false);
        }
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const responsive_openCloseSidebar = (toggle) => {
    if (width <= 991) {
      toggleSidebar(!toggle);
      // document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
      // document.querySelector(".bg-overlay1").classList.add("active");
    } else {
      if (toggle) {
        toggleSidebar(!toggle);
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon ";
      } else {
        //console.log("991 54 else", toggle);
        toggleSidebar(!toggle);
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
      }
    }
  };

  // const responsive_openCloseSidebar = (toggle) => {
  //   if (width <= 991) {
  //     document.querySelector('.page-header').className = 'page-header';
  //     document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
  //     // document.querySelector('.bg-overlay').classList.add('active');
  //   } else {
  //     if (toggle) {
  //       setSidebartoggle(!toggle);
  //       document.querySelector('.page-header').className = 'page-header close_icon';
  //       document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
  //       document.querySelector('.mega-menu-container').classList.remove('d-block');
  //     } else {
  //       setSidebartoggle(!toggle);
  //       document.querySelector('.page-header').className = 'page-header';
  //       document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
  //     }
  //   }
  // };
  
  return (
    <Fragment>
      <Col className='header-logo-wrapper col-auto p-0' id='out_side_click'>
        <div className='logo-wrapper' style={{fontSize: 26, fontWeight: 450, color:'#09256a', width:300}}>
          {
            theme === 'dark-only' ? 
            <Link to={`${process.env.PUBLIC_URL}/pages/home`} style={{fontSize: 16, fontWeight: 450, color:'#09256a', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <Image attrImage={{ className: 'img-fluid for-dark d-inline', src: `${InformLogo}`, alt: '', style: { width: '40px', height: '40px', marginRight: 15, filter: 'brightness(0) invert(100%)' } }} />
            <span style={{fontFamily: 'Space Grotesk, sans-serif' ,fontSize: 28, fontWeight: 'bold',filter: 'brightness(0) invert(100%)'}}>InForm</span>
          </Link>
          :
            <Link to={`${process.env.PUBLIC_URL}/pages/home`} style={{fontSize: 16, fontWeight: 450, color:'#09256a', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <Image attrImage={{ className: 'img-fluid d-inline', src: `${InformLogo}`, alt: '', style: { width: '40px', height: '40px', marginRight: 15 } }} />
            <span style={{fontFamily: 'Space Grotesk, sans-serif' , fontSize: 28, fontWeight: 'bold'}}>InForm</span>
          </Link>
          }
         
         
          {/* InPro group */}
        </div>
        
        {/* Mobile view */}
        <div className='toggle-sidebar' onClick={() => responsive_openCloseSidebar(sidebartoggle)} style={window.innerWidth <= 991 ? { display: 'block' } : { display: 'none', abckground: 'red' }}>
          <AlignCenter className='status_toggle middle sidebar-toggle' id='sidebar-toggle' />
        </div>
      </Col>
      <Col xxl='5' xl='6' lg='5' md='4' sm='3' className='left-header p-0'>
        {/* <NotificationSlider /> */}
      </Col>
    </Fragment>
  );
};

export default Leftbar;
