import React, { Fragment, useState, useEffect } from 'react';

import { Col } from 'reactstrap';
import { UL } from '../../../AbstractElements';
import Language from './Language';
import MoonLight from './MoonLight';
import Searchbar from './Searchbar';
import UserHeader from './UserHeader';
import UserHeaderProfile from './UserHeaderProfile';
import MobileUserHeader from './MobileUserHeader';
import "./index.scss";

const RightHeader = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 990);
    };

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Fragment>
      <Col xxl='7' xl='6' md='7' className='nav-right pull-right right-header col-8 p-0 ms-auto'>
        <UL attrUL={{ className: 'simple-list nav-menus flex-row' }}>
          {isMobileView && <Searchbar isMobileView={isMobileView} />}
          <Language />
          <MoonLight />
          {/* <UserHeader /> */}
          <UserHeaderProfile />
          {/* <MobileUserHeader /> */}
        </UL>
      </Col>
    </Fragment>
  );
};

export default RightHeader;
