import { compareAsc, compareDesc, isValid, parseISO } from 'date-fns';
import format from "date-fns/format";
import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { H6 } from '../../../AbstractElements';
import useIsMobile from '../../../Hooks/useIsMobile';
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import { useProject } from '../../../_helper/Projects/ProjectProvider';
import { useReport } from '../../../_helper/Reports/ReportProvider';
import CustomPagination from '../../CustomPagination';
import CommonModal from '../../Modals/common/modal';
import { TicketSkeleton } from '../../Skeleton/Skeleton';
import { NoData } from './NoData';

const TicketPage = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const deviceWidth = window.innerWidth;
  const { theme, isDark, authToken } = useAuth()
  const { searchText, projectAssetsList, getAllProjectAssets } = useProject()
  const { isFetchingReports, reportLists, getReports, myReportLists, getMyReports, isFetchingDate, date, getDate, getChecklist, isFetchingAsset, assetType, getAssetType } = useReport()
  const [modalData, setModalData] = useState(null);
  const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () => setLarge(!Large);
  const browserLocale = navigator.language || navigator.userLanguage; // Get the browser's locale

  const [sortStatus, setSortStatus] = useState('date');
  const [sortByFirstProperties, setSortByFirstProperties] = useState('asc');
  const [sortByAssetTypeName, setsortByAssetTypeName] = useState('asc');
  const [sortByDate, setSortByDate] = useState('asc');
  const [sortByPercent, setSortByPercent] = useState('asc');

  const [popoverIndex, setPopoverIndex] = useState(null);

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate start and end indexes of items for the current page
  const startIndex = (currentPage - 1) * itemPerPage;
  const endIndex = Math.min(startIndex + itemPerPage, reportLists.length);

  const handleClick = (item) => {
    setModalData(item)
  }

  const handleMouseEnter = (index) => {
    setPopoverIndex(index);
  };

  const handleMouseLeave = () => {
    setPopoverIndex(null);
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '(...)';
    } else {
      return text;
    }
  }

  useEffect(() => {
    if (authToken) {
      getReports(authToken);
      getMyReports(authToken);
    }
  }, [authToken])

  //Array manipulation by adding percent, checklist, and dateCreated
  function modifyArray1WithPercentAndChecklist(array1, array2) {
    // Group data of array2 by parentId into an array of objects
    const checklistMap = array2.reduce((acc, obj) => {
      if (!acc[obj.parentId]) {
        acc[obj.parentId] = [];
      }
      acc[obj.parentId].push(obj); // Group the object under its parentId
      return acc;
    }, {});

    // Compute the percentage from grouped data
    const groupedPercentages = Object.keys(checklistMap).reduce((acc, parentId) => {
      const group = checklistMap[parentId];
      const totalPercent = group.reduce((sum, item) => sum + (item.percent || 0), 0);
      const averagePercent = totalPercent / group.length; // Calculate average percentage
      acc[parentId] = Math.round(averagePercent); // Round to nearest whole number
      return acc;
    }, {});

    const browserLocale = navigator.language || navigator.userLanguage; // Get the browser's locale
    const locale = browserLocale === 'de-DE' ? 'de-DE' : undefined; // Set locale to 'de-DE' if it matches, otherwise undefined

    const dateMap = date.reduce((acc, obj) => {

      if (locale !== 'de-DE') {
        acc[obj.parentId] = new Date(obj.dateTime).toLocaleDateString(locale);
        return acc;
      }
      acc[obj.parentId] = format(new Date(obj.dateTime), 'yyyy.MM.dd');
      return acc;
    }, {});

    const typeMap = assetType.reduce((acc, arr) => {
      arr.forEach(obj => {
        acc[obj._id] = obj.name;
      });
      return acc;
    }, {});
 
    const dateCreatedMap = date.reduce((acc, obj) => {
      acc[obj.parentId] = obj.dateTime;
      return acc;
    }, {});

    array1.forEach(obj => {
      obj.percent = groupedPercentages[obj._id] !== undefined ? groupedPercentages[obj._id] : 0;
      obj.checkList = checklistMap[obj._id] !== undefined ? checklistMap[obj._id] : [];
      obj.dateCreated = dateMap[obj._id];
      obj.assetTypeName = typeMap[obj.typeId] || "";
      obj.dateCreatedOn = dateCreatedMap[obj._id];
    });

    return array1;
  }
  modifyArray1WithPercentAndChecklist(reportLists, myReportLists);

  // Function to toggle sorting order
  const toggleAssetTypeNameSort = () => {
    setsortByAssetTypeName((prevSort) => (prevSort === 'desc' ? 'asc' : 'desc'));
    setSortStatus('assetTypeName')
  };

  const toggleDateSort = () => {
    setSortByDate((prevSort) => (prevSort === 'desc' ? 'asc' : 'desc'));
    setSortStatus('date')
  };

  const toggleSortByFirstProperties = () => {
    setSortByFirstProperties((prevSort) => (prevSort === 'desc' ? 'asc' : 'desc'));
    setSortStatus('firstProjectAssetsProperties')
  };

  const toggleSortByPercent = () => {
    setSortByPercent((prevSort) => (prevSort === 'desc' ? 'asc' : 'desc'));
   setSortStatus('percent')
  };

  const combinedDataProjectAssets = reportLists.map(report => {
    // Find the matching item in data based on typeId
    const matchedItem = assetType.flat().find(item => item._id === report.typeId);

    // If a match is found, combine the matched item with the report
    if (matchedItem) {
        return {
            ...report,
            projectAssets: matchedItem,
        };
    }

    // If no match is found, return the report as is
    return report;
});

const modifiedCombinedDataProjectAssets = combinedDataProjectAssets.map((item) => {
  const firstProjectAssetsProperties =
    item?.projectAssets?.properties?.[0] ? {
      mode: item?.projectAssets?.properties[0]?.mode,
      customizeType: item?.projectAssets?.properties[0]?.customizeType,
      value: 
        item?.projectAssets?.properties[0]?.mode === 2 
          ? item?.properties[0]?.valueInt 
          : item?.projectAssets?.properties[0]?.mode === 3 
            ? item?.properties[0]?.valueDateTime?.slice(0, 10) 
            : item?.projectAssets?.properties[0]?.mode === 4 && item?.projectAssets?.properties[0]?.customizeType === "TEXT"
            ? item?.properties[0]?.valueString
            : item?.projectAssets?.properties[0]?.mode === 4 && item?.projectAssets?.properties[0]?.customizeType === "TEXT2"
            ? item?.properties[0]?.valueString
            : item?.properties[0]?.valueString
    } : null;

  return {
    ...item,
    firstProjectAssetsProperties
  };
});

const filteredArray = modifiedCombinedDataProjectAssets.filter(item => {
  const isNumericSearch = !isNaN(searchText); // Check if searchText is a valid number
  const searchValue = searchText.toLowerCase(); // Search text in lowercase for string comparison
  
  // Filter by state, exclude specific typeIds, and check if searchText matches any string fields
  return (
    item.state === 0 && // Filter by state first
    item.typeId !== '5f91685379f04e354891f866' && // Exclude specific typeIds
    item.typeId !== '63f30c8c971f7d687d16b0b9' && // Exclude specific typeIds
    (
      item?.name.toLowerCase().includes(searchValue) ||
      item?.description.toLowerCase().includes(searchValue) ||
      item?.assetTypeName.toLowerCase().includes(searchValue) ||
      item?.firstProjectAssetsProperties?.value.toLowerCase().includes(searchValue) ||
      (isNumericSearch && item?.percent === Number(searchText)) // Filter by numeric value
    )
  );
});

// Filter out invalid dates
const validData = filteredArray.filter(
  (item) => item.dateCreatedOn && isValid(parseISO(item.dateCreatedOn))
);

// Sort using date-fns dynamically based on sortBy
const sortedCombinedArray = validData.sort((a, b) => {
  if (sortStatus === 'date') {
    // Sort by date
    const dateComparison =
      sortByDate === 'desc'
        ? compareAsc(parseISO(a.dateCreatedOn), parseISO(b.dateCreatedOn))
        : compareDesc(parseISO(a.dateCreatedOn), parseISO(b.dateCreatedOn));

    if (dateComparison === 0) {
      sortStatus = 'assetTypeName'; // Change sortStatus to assetTypeName when dates are equal
    }

    return dateComparison;
  }

  if (sortStatus === 'assetTypeName') {
    // Sort by firstProjectAssetsProperties value
    const valueA = a?.assetTypeName || '';
    const valueB = b?.assetTypeName || '';

    return valueA < valueB
      ? sortByAssetTypeName === 'asc'
        ? -1
        : 1
      : valueA > valueB
      ? sortByAssetTypeName === 'asc'
        ? 1
        : -1
      : 0;
  }

  if (sortStatus === 'firstProjectAssetsProperties') {
    // Sort by firstProjectAssetsProperties value
    const valueA = a?.firstProjectAssetsProperties?.value || '';
    const valueB = b?.firstProjectAssetsProperties?.value || '';

    return valueA < valueB
      ? sortByFirstProperties === 'asc'
        ? -1
        : 1
      : valueA > valueB
      ? sortByFirstProperties === 'asc'
        ? 1
        : -1
      : 0;
  }

  if (sortStatus === 'percent') {
    // Sort by percent value
    const valueA = a?.percent || 0;
    const valueB = b?.percent || 0;

    return valueA < valueB
      ? sortByPercent === 'asc'
        ? -1
        : 1
      : valueA > valueB
      ? sortByPercent === 'asc'
        ? 1
        : -1
      : 0;
  }

  return 0; // If no sort is active, return 0 (no change)
});

  useEffect(() => {
    const fetchDate = async () => {
      try {
        await Promise.all(reportLists.map(async (item) => {
          getDate(authToken, item._id)
        }));

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchAsset = async () => {
      try {
        await Promise.all(reportLists.map(async (item) => {
          getAssetType(authToken, item.typeId)
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchAsset();
    fetchDate();
  }, [reportLists]);

  useEffect(() => {
    // Set page to 1 when searchText is not empty
    if (searchText !== '') {
      setCurrentPage(1);
    }
  }, [searchText]);

function highlightText(text, searchText, isDarkMode) {
  if (!searchText) return text;

  const regex = new RegExp(`(${searchText})`, 'gi');
  return text.split(regex).map((part, index) =>
    part.toLowerCase() === searchText.toLowerCase() ? (
      <span
        key={index}
        style={{
          backgroundColor: isDarkMode ? '#FFD700' : '#FFFF00', // Gold for dark mode, Yellow for light mode
          color: isDarkMode ? '#000000' : '#000000', // Ensure text is readable
        }}
      >
        {part}
      </span>
    ) : (
      part
    )
  );
}

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
}


  return (
    <Fragment>
      <Container fluid={true}>

        <Row>
          <Col sm="12" md="10" className="offset-md-1">
            <Row style={{ marginTop: 30 }}>
              <Col sm="12" xl="12">
                <Accordion defaultActiveKey="0">
                  <Card>
                    {
                      isFetchingReports ? (
                        <TicketSkeleton />
                      ) : 
                        (searchText !== "" && sortedCombinedArray.length === 0) || sortedCombinedArray.length === 0 ? (
                          <NoData message='No ticket found' />
                        ) : (reportLists.length === 0 || sortedCombinedArray.length === 0 ? (
                         <NoData message='No Data' />
                        ) : (
                          <CardBody>
                            <div className="table-responsive table-hover p-0">
                              <Table hover={true}>
                              {sortedCombinedArray.length > 0 && (
                                <thead>
                                <tr>
                                <td className="text-left" style={{ background: 'none' }}>
                                    <button
                                      className="btn p-0 pt-1"
                                      style={{ width: '35px', height: '35px', borderRadius: '50%', border: '1px solid #e0e4ed', background: sortStatus === 'assetTypeName' ? (theme === 'dark-only' ? '#434858' : '#e0e4ed') : '' }}
                                      onClick={() => toggleAssetTypeNameSort()}
                                    >
                                      <i
                                        style={{ fontSize: 20 }}
                                        className={`for-light fa ${sortByAssetTypeName === 'asc' ? 'fa-sort-alpha-desc' : 'fa-sort-alpha-asc'}`}
                                      ></i>
                                      <i
                                        style={{ fontSize: 20, color: '#a8a9ad' }}
                                        className={`for-dark fa ${sortByAssetTypeName === 'asc' ? 'fa-sort-alpha-desc' : 'fa-sort-alpha-asc'}`}
                                      ></i>
                                    </button>
                                  </td>
                                  {
                                    isMobile ? '' :
                                    <td className="text-left" style={{ background: 'none' }}>
                                      <button
                                        className="btn p-0 pt-1"
                                        style={{ width: '35px', height: '35px', borderRadius: '50%', border: '1px solid #e0e4ed', background: sortStatus === 'firstProjectAssetsProperties' ? (theme === 'dark-only' ? '#434858' : '#e0e4ed') : '' }}
                                        onClick={() => toggleSortByFirstProperties()}
                                      >
                                        <i
                                          style={{ fontSize: 20 }}
                                          className={`for-light fa ${sortByFirstProperties === 'desc' ? 'fa-sort-alpha-desc' : 'fa-sort-alpha-asc'}`}
                                        ></i>
                                        <i
                                          style={{ fontSize: 20, color: '#a8a9ad' }}
                                          className={`for-dark fa ${sortByFirstProperties === 'desc' ? 'fa-sort-alpha-desc' : 'fa-sort-alpha-asc'}`}
                                        ></i>
                                      </button>
                                    </td>
                                  }
                                  <td className="text-center" style={{ background: 'none' }}>
                                    <button
                                      className="btn p-0 pt-1"
                                      style={{ width: '35px', height: '35px', borderRadius: '50%', border: '1px solid #e0e4ed', background: sortStatus === 'date' ? (theme === 'dark-only' ? '#434858' : '#e0e4ed') : '' }}
                                      onClick={() => toggleDateSort()}
                                    >
                                      <i
                                        style={{ fontSize: 20 }}
                                        className={`for-light fa ${sortByDate === 'asc' ? 'fa-sort-numeric-desc' : 'fa-sort-numeric-asc'}`}
                                      ></i>
                                      <i
                                        style={{ fontSize: 20, color: '#a8a9ad' }}
                                        className={`for-dark fa ${sortByDate === 'asc' ? 'fa-sort-numeric-desc' : 'fa-sort-numeric-asc'}`}
                                      ></i>
                                    </button>
                                  </td><td className="text-center" style={{ background: 'none' }}>
                                    <button
                                      className="btn p-0 pt-1"
                                      style={{ width: '35px', height: '35px', borderRadius: '50%', border: '1px solid #e0e4ed', background: sortStatus === 'percent' ? (theme === 'dark-only' ? '#434858' : '#e0e4ed') : '' }}
                                      onClick={() => toggleSortByPercent()}
                                    >
                                      <i
                                        style={{ fontSize: 20 }}
                                        className={`for-light fa ${sortByPercent === 'desc' ? 'fa-sort-numeric-desc' : 'fa-sort-numeric-asc'}`}
                                      ></i>
                                      <i
                                        style={{ fontSize: 20, color: '#a8a9ad' }}
                                        className={`for-dark fa ${sortByPercent === 'desc' ? 'fa-sort-numeric-desc' : 'fa-sort-numeric-asc'}`}
                                      ></i>
                                    </button>
                                  </td>
                                </tr>
                              </thead>
                              )}
                              <tbody>
                                {sortedCombinedArray?.slice(startIndex, endIndex).map((item, index) => (
                                  <React.Fragment key={item._id}>
                                    {
                                      isMobile ? (
                                        <tr key={item._id} onClick={() => [handleClick(item), LargeModaltoggle()]} style={{height: 80}}>
                                          <td style={{ width: '30%', background: 'none' }}>
                                            <H6>
                                              {isFetchingAsset
                                                ? 'Loading...'
                                                : item.assetTypeName === ''
                                                ? '...'
                                                : highlightText(
                                                    truncateText(
                                                      item.assetTypeName,
                                                      deviceWidth <= 375 ? 10 :
                                                      deviceWidth <= 1024 ? 10 :
                                                      isMobile ? 15 : 50
                                                    ),
                                                    searchText,
                                                    isDark
                                                  )
                                              }
                                            </H6>
                                            <div style={{ color: '#6c757d', width: '100%', position: 'absolute', }}>
                                              {highlightText(
                                                truncateText(
                                                  item?.firstProjectAssetsProperties?.value || '...',
                                                  deviceWidth < 320 ? 15 :                     // Extra small mobile screens (less than 320px wide)
                                                  deviceWidth <= 360 ? 25 :                    // Small mobile screens (up to 360px wide)
                                                  deviceWidth <= 375 ? 35 :                    // Small mobile screens (portrait mode)
                                                  deviceWidth <= 414 ? 40 :                    // Larger small screens (portrait mode)
                                                  deviceWidth <= 480 ? 45 :                    // Medium mobile screens
                                                  deviceWidth <= 600 ? 50 :                    // Larger mobile screens or small tablets
                                                  deviceWidth <= 768 ? 50 :                    // Mobile in landscape or small tablets
                                                  deviceWidth <= 800 ? 60 :                    // Mobile in landscape or small tablets
                                                  50                                           // Default truncation for larger mobiles
                                                ),
                                                searchText,
                                                isDark
                                              )}
                                            </div>
                                          </td>
                                          
                                          <td className="text-center" style={{ width: '20%', background: 'none' }}>
                                            {isFetchingDate
                                              ? 'Loading...'
                                              : highlightText(item.dateCreated, searchText, isDark)}
                                          </td>
                                          <td className="text-center" style={{ width: '20%', background: 'none' }}>
                                          <div
                                            style={{
                                              color: '#fff',
                                              backgroundColor:
                                                item.percent === 0
                                                  ? '#bebfbf'
                                                  : item.percent >= 1 && item.percent <= 98
                                                  ? '#f07b05'
                                                  : '#77b800',
                                              padding: '2px',
                                              borderRadius: '2px',
                                              marginRight: '8px',
                                              width: deviceWidth <= 1024 ? '50px' : 'auto', // Fixed width for mobile view
                                              textAlign: 'center', // Ensures text is centered
                                              display: 'inline-block', // Allows the width to take effect
                                            }}
                                          >
                                            {item.percent}%
                                          </div>

                                          </td>
                                        </tr>
                                      ) : (
                                        <tr key={item._id} onClick={() => [handleClick(item), LargeModaltoggle()]}>
                                          <td style={{ width: '30%', background: 'none' }}>
                                            <H6>
                                              {isFetchingAsset
                                                ? 'Loading...'
                                                : item.assetTypeName === ''
                                                ? '...'
                                                : highlightText(item.assetTypeName, searchText, isDark)}
                                            </H6>
                                          </td>
                                          <td style={{ width: '30%', background: 'none' }}>
                                            {highlightText(item?.firstProjectAssetsProperties?.value, searchText, isDark)}
                                          </td>
                                          <td className="text-center" style={{ width: '20%', background: 'none' }}>
                                            {isFetchingDate
                                              ? 'Loading...'
                                              : highlightText(item.dateCreated, searchText, isDark)}
                                          </td>
                                          <td className="text-center" style={{ width: '20%', background: 'none' }}>
                                          <span
                                            style={{
                                              color: '#fff',
                                              backgroundColor:
                                                item.percent === 0
                                                  ? '#bebfbf'
                                                  : item.percent >= 1 && item.percent <= 98
                                                  ? '#f07b05'
                                                  : '#77b800',
                                              padding: '5px 20px',
                                              borderRadius: '2px',
                                              marginRight: '8px',
                                              whiteSpace: 'nowrap',
                                              width: '80px', // Fixed width
                                              textAlign: 'center', // Ensures text is centered
                                              display: 'inline-block', // Allows the width to take effect
                                            }}
                                          >
                                            {item.percent}%
                                          </span>

                                          </td>
                                        </tr>
                                      )
                                    }
                                  </React.Fragment>
                                ))}
                              </tbody>
                              </Table>
                            </div>
                            {sortedCombinedArray.length > 0 && (
                              <CustomPagination
                                totalItems={sortedCombinedArray.length}
                                itemsPerPage={itemPerPage}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                                onItemsPerPageChange={setItemPerPage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                searchText={searchText}
                              />
                            )}
                          </CardBody>
                        )
                      )
                    }
                  </Card>
                </Accordion>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <CommonModal isOpen={Large} toggler={LargeModaltoggle} size="lg" data={modalData}>...</CommonModal>

    </Fragment>
  )
}

export default TicketPage