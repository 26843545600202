import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import Masonry from 'react-masonry-css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { Btn, H4 } from '../../../AbstractElements';
import { useAuth } from "../../../_helper/Auth/AuthProvider";
import { useProject } from '../../../_helper/Projects/ProjectProvider';
import { ConcernSkeleton } from '../../Skeleton/Skeleton';
import './style.scss';
import { toast } from 'react-toastify'

const ConcernPage = () => {
  const { theme, isDark, user, authToken } = useAuth();
  const { t } = useTranslation()
  const history = useNavigate();
  const isConcernPage = useLocation().pathname.includes("/pages/concern");
  const { parentId : parentId, projectId : projectId , assetId: assetId, category: category, assetName: assetName } = useParams();
  const { projectAssetsList, getAllProjectAssets, submitTicket, isTicketSubmitted, setIsTicketSubmitted, groupedAssets } = useProject()
  const [currentDateTime, setCurrentDateTime] = useState('')

  //for api connection
  const backendUrl = process.env.REACT_APP_BACKEND_URL
  const backendPort = process.env.REACT_APP_BACKEND_PORT
  let apiUrl = `${backendUrl}`
  if (backendPort) {
    apiUrl += `:${backendPort}`
  }

  //for masonry layout columns
  const breakpointColumnsObj = {
    default: 1,
    1199: 1,
    700: 1,
    500: 1,
  }

  useEffect(() => {
    const getCurrentDateTime = () => {
      const currentDate = new Date()
      const formattedDateTime = format(
        currentDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      )
      setCurrentDateTime(formattedDateTime)
    }
    getCurrentDateTime()
    const interval = setInterval(getCurrentDateTime, 1000) // Update current date and time every second
    return () => clearInterval(interval)
  }, [])

  let assetFormList;
  if (projectAssetsList.length === 0) {
    getAllProjectAssets(assetId)
  } else {
      const projectAssetFormList = projectAssetsList?.filter((i)=>i._id === assetId).map((item)=>{ return (item)})
    assetFormList = projectAssetFormList[0];
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [hasChanged, setHasChanged] = useState(false);
  // Watch all fields to track current values
  const watchedFields = watch();
  const [loading, setLoading] = useState(false);

  const submitTicketForm = async (data) => {
    const today = new Date(); // Get the current date
    const formattedDate = moment(today).format('YYYY-MM-DDTHH:mm:ss[Z]');

    // Construct an array of form field objects
    const propertiesData = assetFormList?.properties.map((form, index) => {
      if (form.mode === 1) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueBool: ['true', 'yes', '1'].includes(data[`Name${index}`]?.toLowerCase())
        };
      } else if (form.mode === 2) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueInt: data[`Name${index}`]
        };
      } else if (form.mode === 3) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
        };
      } else if (form.mode === 4) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueString: data[`Name${index}`]
        };
      } else if (form.mode === 5) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueDecimal: data[`Name${index}`]
        };
      }
      else if (form.mode === 7) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueString: data[`Name${index}`]
        };
      }
      else {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueTimeStamp: data[`Name${index}`]
        };
      }
    });

    const ticketData = {
      data: {
        chatRoomThema: [
          {
            "title": "allgemein",
            "description": "",
            "createdOnDate": currentDateTime,
            "changedOnDate": currentDateTime
          }
        ],
        parentId: parentId,
        project_id: projectId,
        typeId: assetId,
        name: data.subject,
        description: '',
        properties: propertiesData,
      }

    };

    //console.log('ticketData', ticketData)
    
    // try {
    //   setLoading(true);
    //   if (authToken) {
    //     const response = await axios.post((apiUrl += '/api/submit-ticket'), {
    //       token: authToken,
    //       ticketData: ticketData,
    //     })
    //     if (response.data.success) {
    //       toast.success(response.data.message, { autoClose: 1000 });
    //       setIsTicketSubmitted(true);
    //     } else {
    //       toast.error(response?.data?.message || 'Something went wrong.', { autoClose: 1000 });
    //     }
    //   }
    // } catch (err) {
    //   toast.error('Something went wrong.', { autoClose: 1000 });
    // } finally {
    //   setLoading(false);
    // }

     await submitTicket(ticketData, setLoading);
  };

  if (isTicketSubmitted) {
    history(`${process.env.PUBLIC_URL}/pages/home`)
    setIsTicketSubmitted(false)
  }

  const customizeEntries = assetFormList?.customizeEntries || [];
  const assetProperty = assetFormList?.properties.map((properties) => {
    const options = { option: customizeEntries.filter(customizeEntry => properties.customizeType === customizeEntry._id) };
    return { ...properties, options };
  }) || [];


  // Set initial values when the component mounts
  const initialValues = assetProperty?.reduce((acc, form, index) => {
    acc[`Name${index}`] = ''; // Initialize empty fields
    return acc;
  }, {});

  useEffect(() => {
    // Check if any field value has changed
    const changed = Object.entries(watchedFields).some(
      ([key, value]) => initialValues[key] !== value
    );
    setHasChanged(changed);
  }, [watchedFields]);

  // console.log('assetProperty', assetProperty)
  return (
    <Fragment>
      {/* <ToastContainer autoClose={1000}/> */}
      {/* <Breadcrumbs mainTitle="Concern" parent="Pages" title="Concern" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12" md="10" className="offset-md-1">
            <Row style={{ marginTop: 30 }}>
              <Col sm="12" style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                {
                  projectAssetsList.length === 0 ?
                    <ConcernSkeleton />
                    : <Card style={{ width: 800}} >
                      <CardHeader>
                        <H4>{category} - {assetName}</H4>
                        {/* <div>{`${format(new Date(), 'yy-MM-dd')}_${user?.username}`}</div> */}
                      </CardHeader>

                      <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(submitTicketForm)}>
                          <Row style={{ marginBottom: 50 }}>
                            <Col md='12' style={{ display: 'none' }}>
                              <div className="form-floating mb-3">
                                <input className="form-control" id='validationCustom01' readOnly type='text' name={`subject`} {...register('subject', { required: true })} value={`${format(new Date(), 'yy-MM-dd')}_${user?.username}`} />
                                <label htmlFor="floatingInput22"><span style={{ color: '#F07B05' }}>*</span>Subject</label>
                                <span className='d-block' style={{ color: '#F07B05' }}>{errors[`subject`] && 'This field is required'}</span>
                              </div>
                            </Col>
                            <Col>
                              <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid masonry-with-dec my-gallery gallery-with-description row grid"
                                columnClassName="col-xl-12 col-sm-12 col-md-12 grid-item"
                              >
                                {
                                  assetProperty.map((form, index) => (
                                    <div className="form-group mb-3" style={{ position: "relative" }}>
                                      {/* Input Field Based on Form Mode */}
                                      {form.mode === 2 ? (
                                        <input
                                          className="form-control"
                                          id={`validationCustom01-${index}`}
                                          type="number"
                                          name={`Name${index}`}
                                          {...register(`Name${index}`, { required: form.mandatory })}
                                          style={{
                                            minHeight: "40px",
                                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))", // Adjust height dynamically
                                            transition: "padding-top 0.2s ease-in-out",
                                          }}
                                        />
                                      ) : form.mode === 3 ? (
                                        <input
                                          className="form-control"
                                          id={`validationCustom01-${index}`}
                                          type="date"
                                          name={`Name${index}`}
                                          {...register(`Name${index}`, { required: form.mandatory })}
                                          style={{
                                            minHeight: "40px",
                                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                                            transition: "padding-top 0.2s ease-in-out",
                                          }}
                                        />
                                      ) : form.mode === 4 && form.customizeType === "TEXT" ? (
                                        <input
                                          className={`form-control ${theme === 'dark-only' ? 'dark-only' : 'light-only'}`}
                                          id={`validationCustom01-${index}`}
                                          type="text"
                                          name={`Name${index}`}
                                          {...register(`Name${index}`, { required: form.mandatory })}
                                          style={{
                                            minHeight: "40px",
                                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                                            transition: "padding-top 0.2s ease-in-out",
                                          }}
                                        />
                                      ) : form.mode === 4 && form.customizeType === "TEXT2" ? (
                                        <textarea
                                          className={`form-control textarea-custom-scrollbar ${theme === 'dark-only' ? 'dark-only' : 'light-only'}`}
                                          id={`validationCustom01-${index}`}
                                          style={{
                                            height: "auto",
                                            minHeight: "80px",
                                            //maxHeight: "300px",
                                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                                            transition: "padding-top 0.2s ease-in-out",
                                          }}
                                          name={`Name${index}`}
                                          {...register(`Name${index}`, { required: form.mandatory })}
                                          onInput={(e) => {
                                            e.target.style.height = "auto";
                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                          }}
                                        ></textarea>
                                      ) : form.mode === 5 ? (
                                        <input
                                          className={`form-control ${theme === 'dark-only' ? 'dark-only' : 'light-only'}`}
                                          id={`validationCustom01-${index}`}
                                          type="number"
                                          name={`Name${index}`}
                                          {...register(`Name${index}`, { required: form.mandatory })}
                                          style={{
                                            minHeight: "40px",
                                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                                            transition: "padding-top 0.2s ease-in-out",
                                          }}
                                        />
                                      ) : form.mode === 7 ? (
                                        <select
                                          className="form-select form-control"
                                          id={`validationCustom01-${index}`}
                                          name={`Name${index}`}
                                          {...register(`Name${index}`, { required: form.mandatory })}
                                          defaultValue=""
                                          style={{
                                            minHeight: "40px",
                                            paddingTop: "calc(24px + var(--dynamic-label-height, 0px))",
                                            transition: "padding-top 0.2s ease-in-out",
                                          }}
                                        >
                                          <option value=""></option>
                                          {form.options.option[0].predefiniedProperties.map((property, i) => (
                                            <option key={i} value={property.value}>
                                              {property.value}
                                            </option>
                                          ))}
                                        </select>
                                      )
                                      :
                                      <div className="radio-container">
                                        <label className="custom-radio-label w-100" htmlFor={`Name${index}`}>
                                          {form.mandatory === true && <span style={{ color: '#F07B05' }}>*</span>}
                                          {form.name}
                                        </label>

                                        <div className="radio-options" style={{ display: 'flex', alignItems: 'center' }}>
                                          <div className="radio-option" style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                              className="custom-radio"
                                              id={`no-${index}`}
                                              type="radio"
                                              name={`Name${index}`}
                                              value={false}
                                              {...register(`Name${index}`, { required: true })}
                                            />
                                            <label className="custom-radio-label" htmlFor={`no-${index}`} style={{ marginLeft: '8px' }}>
                                              {t('No')}
                                            </label>
                                          </div>

                                          <div className="radio-option" style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                                            <input
                                              className="custom-radio"
                                              id={`yes-${index}`}
                                              type="radio"
                                              name={`Name${index}`}
                                              value={true}
                                              {...register(`Name${index}`, { required: true })}
                                            />
                                            <label className="custom-radio-label" htmlFor={`yes-${index}`} style={{ marginLeft: '8px' }}>
                                              {t('Yes')}
                                            </label>
                                          </div>
                                        </div>
                                      </div>}

                                      {/* Label (Relative to Input) */}
                                      {form.mode !== 1 && (
                                        <label
                                          htmlFor={`validationCustom01-${index}`}
                                          className="form-label"
                                          style={{
                                            position: "absolute",
                                            top: "8px",
                                            left: "12px",
                                            fontSize: "12px",
                                            maxWidth: "calc(100% - 24px)", // Prevent overflow
                                            wordWrap: "break-word",
                                            transition: "top 0.2s ease-in-out",
                                            color: theme === 'dark-only' ? "white":""
                                          }}
                                          ref={(el) => {
                                            if (el) {
                                              const labelHeight = el.offsetHeight;
                                              el.parentElement.style.setProperty("--dynamic-label-height", `${labelHeight}px`);
                                            }
                                          }}
                                        >
                                          {form.mandatory && <span style={{ color: "#F07B05" }}>*</span>} {form.name}
                                        </label>
                                      )}

                                      {/* Error Message */}
                                      <span
                                        className="d-block"
                                        style={{ color: "#F07B05", marginTop: "5px" }}
                                      >
                                        {errors[`Name${index}`] &&
                                          (form.mode === 1
                                            ? "Please choose one option and cannot submit before"
                                            : t("This field is required"))}
                                      </span>
                                    </div>


                                    // </Col>
                                  ))
                                }
                              </Masonry>

                            </Col>
                          </Row>
                          <Btn
                            attrBtn={{ color: 'primary', disabled: loading }}
                            className="text-end"
                          >
                            {loading ? `${t('Submitting')}...` : t('Submit')}
                          </Btn>
                        </Form>
                      </CardBody>
                    </Card>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ConcernPage