import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'
import { H6 } from '../../AbstractElements'
import { useProject } from '../../_helper/Projects/ProjectProvider'
import { useAuth } from '../../_helper/Auth/AuthProvider'

const DashboardCard = ({category}) => {
  const { isDark } = useAuth()
  const { t } = useTranslation()
  const { searchText, groupedAssets } = useProject()
  const navigate = useNavigate()
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };
    // Add event listener to track window resize
    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRowClick = (item) => {
    navigate(`${process.env.PUBLIC_URL}/pages/concern/${category}/${item.name}/${item.parentId}/${item.projectId}/${item.assetTypId}/`)
  }

  const filteredRows = groupedAssets[category].filter((asset) =>
    asset.name.toLowerCase().includes(searchText.toLowerCase()) ||
    asset.beschreibung.toLowerCase().includes(searchText.toLowerCase())
  );

  function highlightText(text, searchText, isDarkMode) {
    if (!searchText) return text;
  
    const regex = new RegExp(`(${searchText})`, 'gi');
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === searchText.toLowerCase() ? (
        <span
          key={index}
          style={{
            backgroundColor: isDarkMode ? '#FFD700' : '#FFFF00', // Gold for dark mode, Yellow for light mode
            color: isDarkMode ? '#000000' : '#000000', // Ensure text is readable
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  return (
  <>
    {filteredRows.length === 0 ? (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <p>No results.</p>
      </div>
    ) : (
      <Card className="p-10" style={{ boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px' }}>
        <CardHeader className="pb-1 pt-3">
          <H6 className="m-0">{t(category)}</H6>
        </CardHeader>
        <CardBody
          className="p-0 custom-scrollbar rounded"
          //style={{ maxHeight: 300, overflow: 'auto' }}
        >
          <div className="taskadd">
            <div className="table-responsive table-hover p-0">
              <Table hover={true}>
                <tbody>
                  {filteredRows.map((item, index) => (
                    
                    deviceWidth < 768 ?
                    <tr
                        key={index}
                        onClick={() => handleRowClick(item)}
                        style={{ cursor: 'pointer' }}
                      >
                        <td style={{ width: '30%', background: 'none' }}
                          className="pt-3 pb-0">
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p
                              attrH6={{ className: 'task_title_0' }}
                              style={{
                                fontWeight: '500',
                                marginBottom: '5px'
                              }}
                            >
                              {highlightText(item.name, searchText, isDark)}
                            </p>
                            <p
                              className="text-base"
                              style={{
                                color: '#6c757d',
                                fontSize: '14px',
                                marginTop: 0,
                                marginBottom: 10
                              }}
                            >
                              {highlightText(item.beschreibung, searchText, isDark)}
                            </p>
                          </div>
                        </td>
                      </tr>
                      :
                      <tr
                        key={index}
                        onClick={() => handleRowClick(item)}
                        style={{ cursor: 'pointer' }}
                      >
                        <td
                          style={{ width: '30%', background: 'none', fontWeight: '500' }}
                          className="pt-3 pb-0"
                        >
                          <p attrH6={{ className: 'task_title_0' }}>
                            {highlightText(item.name, searchText, isDark)}
                          </p>
                        </td>
                        <td
                          style={{ background: 'none', color: '#6c757d'}}
                          className="pt-3 pb-3"
                        >
                          <p className="text-base">{highlightText(item.beschreibung, searchText, isDark)}</p>
                        </td>
                      </tr>

                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </CardBody>
      </Card>
    )}
  </>
  )
}

export default DashboardCard
