
export const NoData = ({message}) => {
  return (
    <div
      className="p-5 pb-5 text-center rounded"
      style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px" }}
    >
      <i
        style={{ fontSize: 100, color: "#e0e4ed" }}
        className={`for-light fa fa-file-text-o pb-2`}
      ></i>
      <i
        style={{ fontSize: 100, color: "#343a46" }}
        className={`for-dark fa fa-file-text-o pb-2`}
      ></i>
      <p className="m-0" style={{ color: "#6c757d" }}>
        {message}
      </p>
    </div>
  );
};
