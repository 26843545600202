import React, { createContext, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { APIList } from '../../api/APIList'
import AxiosProvider from '../../api/AxiosProvider'
import { useAuth } from '../Auth/AuthProvider'
import axios from 'axios'

// Create a context for authentication
const CommentContext = createContext()

// Create a provider component
export const CommentProvider = ({ children }) => {
  //context
  const { authToken } = useAuth()
  //state
  const [isCommentSubmitted, setIsCommentSubmitted] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const backendUrl = process.env.REACT_APP_BACKEND_URL
  const backendPort = process.env.REACT_APP_BACKEND_PORT

  let apiUrl = `${backendUrl}`

  if (backendPort) {
    apiUrl += `:${backendPort}`
  }
  const addComment = async (commentData) => {
    setIsSending(true)
    try {
      if (authToken) {
        const response = await axios.post((apiUrl += '/api/add-comment'), {
          token: authToken,
          commentData: commentData,
        })
        if (response.data.success) {
          setIsCommentSubmitted(true)
        } else {
          toast.error('Something went wrong.', {
            autoClose: 1000,
          })
        }
      }
    } catch (err) {
      //console.log(err)
      toast.error('Something went wrong.', {
        autoClose: 1000,
      })
    }finally{
      setIsSending(false)
    }
  }

  return (
    <CommentContext.Provider
      value={{
        isSending,
        isCommentSubmitted,
        setIsCommentSubmitted,
        addComment,
      }}
    >
      {children}
    </CommentContext.Provider>
  )
}

// Custom hook to access the authentication context
export const useComment = () => useContext(CommentContext)
