import axios from 'axios'
import React, { createContext, useContext, useState } from 'react'
import { APIList } from '../../api/APIList'
import { useAuth } from '../Auth/AuthProvider'
import { toast } from 'react-toastify'

// Create a context for authentication
const ReportContext = createContext()

// Create a provider component
export const ReportProvider = ({ children }) => {
  //api
  const getAllProjectApi = APIList.get_all_projects.link
  //context
  const { authToken } = useAuth()
  //state
  const [reportLists, setReportLists] = useState([])
  const [myReportLists, setMyReportLists] = useState([])
  const [isFetchingReports, setIsFetchingReports] = useState(false)
  const [comments, setComments] = useState([])
  const [isFetchingComments, setIsFetchingComments] = useState(false)
  const [checklist, setChecklist] = useState([])
  const [isFetchingChecklist, setIsFetchingChecklist] = useState(false)
  const [date, setDate] = useState([])
  const [isFetchingDate, setIsFetchingDate] = useState(false)
  const [assetType, setAssetType] = useState([])
  const [isFetchingAsset, setIsFetchingAsset] = useState(false)
  const [isTaskUpdate, setIsTaskUpdate] = useState(false)

  const backendUrl = process.env.REACT_APP_BACKEND_URL
  const backendPort = process.env.REACT_APP_BACKEND_PORT

  let apiUrl = `${backendUrl}`

  if (backendPort) {
    apiUrl += `:${backendPort}`
  }

  const getReports = async (token) => {
    setIsFetchingReports(true)
    try {
      const response = await axios.get(apiUrl + '/api/get-reports', {
        headers: {
          token: token,
        },
      })
      setReportLists(response.data)
    } catch (error) {
      // console.log(error)
    } finally {
      setIsFetchingReports(false)
    }
  }

  const getMyReports = async (token) => {
    setIsFetchingReports(true)
    try {
      const response = await axios.get(apiUrl + '/api/get-my-reports', {
        headers: {
          token: token,
        },
      })
      setMyReportLists(response.data)
    } catch (error) {
      // console.log(error)
    } finally {
      setIsFetchingReports(false)
    }
  }

  const getComments = async (token, _id) => {
    setIsFetchingComments(true)
    try {
      const response = await axios.post(apiUrl + '/api/get-comments', {
        token: token,
        id: _id,
      })
      setComments(response.data.result)
    } catch (error) {
      // console.log(error)
    } finally {
      setIsFetchingComments(false)
    }
  }

  const getDate = async (token, assetId) => {
    setIsFetchingDate(true)
    try {
      const response = await axios.post(apiUrl + '/api/get-user-action', {
        token: token,
        assetId: assetId,
      })
      const mappedDates = response.data.result.map((item) => item)
      const dateCreated = mappedDates[0]
      setDate((prevDates) => [...prevDates, dateCreated])
    } catch (error) {
      // console.log(error)
    } finally {
      setIsFetchingDate(false)
    }
  }

  const getAssetType = async (token, asset_type_id) => {
    setIsFetchingAsset(true)
    try {
      const response = await axios.post(apiUrl + '/api/get-asset-type', {
        token: token,
        asset_type_id: asset_type_id,
      })
      const mappedAssets = response.data.result.map((item) => item)
      const assetType = mappedAssets[0]
      setAssetType((prevAsset) => [...prevAsset, response.data.result])
    } catch (error) {
      // console.log(error)
    } finally {
      setIsFetchingAsset(false)
    }
  }

  const getChecklist = async (token, _id) => {
    setIsFetchingChecklist(true)
    try {
      const response = await axios.post(apiUrl + '/api/get-task-checklist', {
        token: token,
        id: _id,
      })
      setChecklist(response.data.result)
    } catch (error) {
      // console.log(error)
    } finally {
      setIsFetchingChecklist(false)
    }
  }

  const updateTaskDetails = async (taskData) => {
    try {
      if (authToken) {
        const response = await axios.post((apiUrl += '/api/update-task-details'), {
          token: authToken,
          taskData: taskData,
        })

        if (response.data.isOk) {
          toast.success('Task data updated successfully!', {
            autoClose: 1000,
          })
          setIsTaskUpdate(true)
        } else {
          toast.error('Something went wrong.', {
            autoClose: 1000,
          })
        }
      }
    } catch (err) {
      //console.log(err)
      toast.error('Something went wrong.', {
        autoClose: 1000,
      })
    }
  }

  return (
    <ReportContext.Provider
      value={{
        isFetchingReports,
        reportLists,
        getReports,
        myReportLists,
        getMyReports,
        isFetchingComments,
        comments,
        getComments,
        isFetchingChecklist,
        checklist,setChecklist,
        getChecklist,
        isFetchingDate,
        date,
        getDate,
        isFetchingAsset,
        assetType,
        getAssetType,
        isTaskUpdate,setIsTaskUpdate,
        updateTaskDetails
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}

// Custom hook to access the authentication context
export const useReport = () => useContext(ReportContext)
