import React, { useContext, useState, useEffect } from 'react';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import CustomizerContext from '../../../_helper/Customizer';
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import useIsMobile from '../../../Hooks/useIsMobile';

const MoonLight = () => {
  const { addMixBackgroundLayout } = useContext(CustomizerContext);
  const { setIsDark } = useAuth();
  const isMobile = useIsMobile()
  const [moonlight, setMoonlight] = useState(() => {
    // Check local storage for the theme preference
    return localStorage.getItem('theme') === 'dark-only';
  });

  useEffect(() => {
    // Set the initial theme based on local storage
    const initialTheme = localStorage.getItem('theme') || 'light-only';
    document.body.className = initialTheme;
    addMixBackgroundLayout(initialTheme);
    setIsDark(initialTheme === 'dark-only');
  }, [addMixBackgroundLayout, setIsDark]);

  const MoonlightToggle = () => {
    const newTheme = moonlight ? 'light-only' : 'dark-only';
    addMixBackgroundLayout(newTheme);
    document.body.className = newTheme;
    localStorage.setItem('theme', newTheme);
    setMoonlight(!moonlight);
    setIsDark(!moonlight);
  };

  return (
    // <li>
    //   <div
    //     className={`mode ${moonlight && 'active'}`}
    //     onClick={MoonlightToggle}
    //   >
    //     <SvgIcon iconId={'moon'} />
    //   </div>
    // </li>
    <li>
      <div
        className={`mode ${moonlight ? 'active' : ''}`}
        onClick={MoonlightToggle}
      >
        <i
          style={{ color: moonlight ? '#ffffff99' : '#2f2f3b' }}
          className={`fa ${moonlight ? 'fa-sun-o' : 'fa-moon-o'}`}
        ></i>
      </div>
    </li>
  );
};

export default MoonLight;