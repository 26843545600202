import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap'
import { UL, LI } from '../../AbstractElements'
import { useAuth } from '../../_helper/Auth/AuthProvider'

export const HomeSkeleton = () => {
  const { theme } = useAuth()
  return (
    <Row>
      <Col sm="12">
        <Card className="p-10">
          {/* <CardHeader className="pt-3 pb-1">
            <Skeleton
              count={1}
              height={30}
              width={'30%'}
              style={{ borderRadius: 10, marginBottom: 10 }}
            />
          </CardHeader> */}
          <CardBody
            className="p-0 custom-scrollbar rounded"
            style={{ maxHeight: 300, overflow: 'auto' }}
          >
            <div className="taskadd">
              <div className="table-responsive table-hover p-0">
                <Table hover={true}>
                  <tbody>
                    {[...Array(3)].map((item, index) => {
                      return (
                        <tr key={index} style={{ backgroundColor: theme === 'dark-only' ? '#262932' : '#ffffff'}}>
                          <td style={{ width: '30%', backgroundColor: theme === 'dark-only' ? '#262932' : '#ffffff' }}>
                            <Skeleton
                              count={1}
                              height={30}
                              style={{ borderRadius: 10, marginBottom: 10 }}
                              baseColor={theme === 'dark-only' ? '#1d1e26' : '#e0e0e0'}
                              highlightColor={theme === 'dark-only' ? '#444' : '#f5f5f5'}
                            />
                          </td>
                          <td style={{ backgroundColor: theme === 'dark-only' ? '#262932' : '#ffffff' }}>
                            <Skeleton
                              count={1}
                              height={30}
                              style={{ borderRadius: 10, marginBottom: 10 }}
                              baseColor={theme === 'dark-only' ? '#1d1e26' : '#e0e0e0'}
                              highlightColor={theme === 'dark-only' ? '#444' : '#f5f5f5'}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export const ConcernSkeleton = () => {
  const { theme } = useAuth()
  return (
    <Card style={{ width: 800 }}>
          <CardHeader>
            <Col sm="12">
              <Skeleton
                count={1}
                height={30}
                width={"30%"}
                style={{ borderRadius: 10, marginBottom: 10 }}
                baseColor={theme === 'dark-only' ? "#1d1e26" : "#e0e0e0"} // Base color for dark/light mode
                highlightColor={theme === 'dark-only' ? "#262932" : "#f5f5f5"} // Highlight color for dark/light mode
              />
            </Col>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="12">
                <Skeleton
                  count={3}
                  height={58}
                  style={{ borderRadius: 10, marginBottom: 10 }}
                  baseColor={theme === 'dark-only' ? "#1d1e26" : "#e0e0e0"}
                  highlightColor={theme === 'dark-only' ? "#262932" : "#f5f5f5"}
                />
              </Col>
              <Col sm="12">
                <Skeleton
                  count={3}
                  height={58}
                  style={{ borderRadius: 10, marginBottom: 10 }}
                  baseColor={theme === 'dark-only' ? "#1d1e26" : "#e0e0e0"}
                  highlightColor={theme === 'dark-only' ? "#262932" : "#f5f5f5"}
                />
              </Col>
              <Col sm="12">
                <Skeleton
                  count={1}
                  height={80}
                  style={{ borderRadius: 10, marginBottom: 10 }}
                  baseColor={theme === 'dark-only' ? "#1d1e26" : "#e0e0e0"}
                  highlightColor={theme === 'dark-only' ? "#262932" : "#f5f5f5"}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Skeleton
              count={1}
              height={35}
              width={"8%"}
              style={{ borderRadius: 10 }}
              baseColor={theme === 'dark-only' ? "#1d1e26" : "#e0e0e0"}
              highlightColor={theme === 'dark-only' ? "#262932" : "#f5f5f5"}
            />
          </CardFooter>
        </Card>
  )
}

export const TicketSkeleton = () => {
  const { theme } = useAuth()
  return (
    <Table
      className="m-t-30"
      style={{
        backgroundColor: theme === 'dark-only' ? '#262932' : '#ffffff', // Dark mode or light mode
        borderRadius: 10,
        padding: '10px',
      }}
    >
      <tbody className="text-center">
        {[...Array(5)].map((_, index) => (
          <tr key={index} >
            <td style={{ backgroundColor: theme === 'dark-only' ? '#262932' : '#ffffff'}}>
              <Skeleton
                count={1}
                height={35}
                width={'100%'}
                style={{ borderRadius: 10 }}
                baseColor={theme === 'dark-only' ? '#1d1e26' : '#e0e0e0'}
                highlightColor={theme === 'dark-only' ? '#444' : '#f5f5f5'}
              />
            </td>
            <td style={{ backgroundColor: theme === 'dark-only' ? '#262932' : '#ffffff'}}>
              <Skeleton
                count={1}
                height={35}
                width={'100%'}
                style={{ borderRadius: 10 }}
                baseColor={theme === 'dark-only' ? '#1d1e26' : '#e0e0e0'}
                highlightColor={theme === 'dark-only' ? '#444' : '#f5f5f5'}
              />
            </td>
            <td style={{ backgroundColor: theme === 'dark-only' ? '#262932' : '#ffffff'}}>
              <Skeleton
                count={1}
                height={35}
                width={'100%'}
                style={{ borderRadius: 10 }}
                baseColor={theme === 'dark-only' ? '#1d1e26' : '#e0e0e0'}
                highlightColor={theme === 'dark-only' ? '#444' : '#f5f5f5'}
              />
            </td>
            <td style={{ backgroundColor: theme === 'dark-only' ? '#262932' : '#ffffff'}}>
              <Skeleton
                count={1}
                height={35}
                width={'100%'}
                style={{ borderRadius: 10 }}
                baseColor={theme === 'dark-only' ? '#1d1e26' : '#e0e0e0'}
                highlightColor={theme === 'dark-only' ? '#444' : '#f5f5f5'}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export const FormSkeleton = () => {
  return (
    <Row>
      {[...Array(5)].map((item) => (
        <Col sm="6">
          <Skeleton
            count={1}
            height={58}
            width={'100%'}
            style={{ borderRadius: 10, marginBottom: 10 }}
          />
        </Col>
      ))}
    </Row>
  )
}

export const ChecklistSkeleton = () => {
  return (
    <UL>
      {[...Array(3)].map((item) => (
        <LI
          attrLI={{
            className: 'clearfix border-0 p-0',
          }}
        >
          <Skeleton
            count={1}
            height={40}
            width={'100%'}
            style={{ borderRadius: 10, marginBottom: 5 }}
          />
        </LI>
      ))}
    </UL>
  )
}

export const ChatSkeleton = () => {
  return (
    <UL>
      <tbody className="text-center">
        <LI
          attrLI={{
            className: 'clearfix border-0 mb-2',
          }}
        >
          <div
            style={{
              borderRadius: 10,
              minWidth: 350,
              display: 'inline-block', // Ensures the width adjusts automatically
              position: 'relative',
            }}
          >
            <Skeleton
              count={1}
              height={100}
              width={'100%'}
              style={{ borderRadius: 10 }}
            />
          </div>
        </LI>
        <LI
          attrLI={{
            className: 'clearfix border-0 mb-2',
          }}
        >
          <div
            style={{
              borderRadius: 10,
              minWidth: 350,
              display: 'inline-block', // Ensures the width adjusts automatically
              position: 'relative',
            }}
          >
            <Skeleton
              count={1}
              height={100}
              width={'100%'}
              style={{ borderRadius: 10 }}
            />
          </div>
        </LI>
      </tbody>
    </UL>
  )
}
